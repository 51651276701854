<template>
  <el-card
    :class="type"
    :shadow="shadow"
    :body-style="bodyStyle"
    :header="header"
  >
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  props: {
    header: { type: String },
    shadow: { type: String },
    bodyStyle: { type: Object },
    type: {
      type: String,
      validator: value =>
        ["primary", "success", "info", "warning", "danger"].includes(value)
    }
  }
};
</script>

<style scoped>
.primary {
  color: #409eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
}

.success {
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}

.info {
  color: #909399;
  background: #f4f4f5;
  border-color: #d3d4d6;
}

.warning {
  color: #e6a23c;
  background: #fdf6ec;
  border-color: #f5dab1;
}

.danger {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
}
</style>
