<template>
  <div id="ProducerExpertSegmentManagement" class="relative">
    <div v-if="bookmarks.length < 2" class="placeholder all-disabled">
      <colored-card type="info" style="background-color: white">
        Vous devez avoir créé au moins 2 marque-pages avant de pouvoir les
        combiner.
      </colored-card>
    </div>

    <el-card shadow="never">
      <div slot="header" class="clearfix card-header">
        <span>Combinaison de marque-pages</span>
        <el-tooltip placement="top">
          <div slot="content">
            Vous pouvez combiner les contacts de vos différents marque-pages
            pour former un filtre.
          </div>
          <div class="inline-block px-2">
            <fa :icon="['fal', 'info-circle']" />
          </div>
        </el-tooltip>
      </div>

      <el-alert v-if="error" class="alert" :title="error" type="error" />

      <el-row :gutter="20" type="flex">
        <el-col :span="16">
          <el-alert
            title="La combinaison de marque-pages ne tiendra pas compte des filtres en cours, qui seront écrasés par le résultat."
            type="info"
            show-icon
            :closable="false"
            center
            effect="dark"
          >
          </el-alert>
          <br />
          <colored-card shadow="never">
            <el-select
              v-model="mainBookmarkId"
              filterable
              placeholder="Selectionnez un marque-page de départ"
              class="w-full"
            >
              <div slot="prefix" class="el-input__icon cursor-pointer">
                <fa :icon="['far', 'bookmark']" />
              </div>
              <el-option
                v-for="bookmark in getAvailableBookmarks(mainBookmarkId)"
                :key="bookmark.qInfo.qId"
                :label="bookmark.qMeta.title"
                :value="bookmark.qInfo.qId"
              >
                <fa :icon="['far', 'bookmark']" />
                <span style="margin-left: 3px">
                  {{ bookmark.qMeta.title }}
                </span>
              </el-option>
            </el-select>
          </colored-card>

          <draggable
            v-model="conditions"
            v-bind="dragOptions"
            class="list-group"
            @start="transitionGroupName = 'flip-list'"
            @end="transitionGroupName = null"
            :disabled="!isDraggable"
          >
            <transition-group type="transition" :name="transitionGroupName">
              <div
                v-for="condition in conditions"
                :key="condition.key"
                class="list-group-item"
                :class="{ 'is-draggable': isDraggable }"
              >
                <colored-card
                  :type="operators[condition.operator].type"
                  shadow="never"
                  class="condition-operator"
                  :body-style="{ padding: '5px 10px 2px 10px' }"
                >
                  <img
                    v-if="operators[condition.operator].img"
                    :src="operators[condition.operator].img"
                    style="float: left; margin-right: 5px; height: 16px"
                  />
                  <i
                    v-if="operators[condition.operator].icon"
                    class="far"
                    :class="operators[condition.operator].icon"
                  />
                  {{ operators[condition.operator].name }}
                </colored-card>
                <colored-card
                  :type="operators[condition.operator].type"
                  shadow="never"
                  :body-style="{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    gridColumnGap: '10px'
                  }"
                >
                  <el-select
                    v-model="condition.bookmarkId"
                    filterable
                    placeholder="Selectionnez un marque-page"
                    @focus="isDraggable = false"
                    @blur="isDraggable = true"
                  >
                    <div slot="prefix" class="el-input__icon cursor-pointer">
                      <fa :icon="['far', 'bookmark']" />
                    </div>
                    <el-option
                      v-for="bookmark in getAvailableBookmarks(
                        condition.bookmarkId
                      )"
                      :key="bookmark.qInfo.qId"
                      :label="bookmark.qMeta.title"
                      :value="bookmark.qInfo.qId"
                    >
                      <fa :icon="['far', 'bookmark']" />
                      <span style="margin-left: 3px">
                        {{ bookmark.qMeta.title }}
                      </span>
                    </el-option>
                  </el-select>
                  <el-button
                    style="padding: 12px"
                    @click="deleteCondition(condition.key)"
                  >
                    <fa :icon="['far', 'trash-alt']" />
                  </el-button>
                </colored-card>
              </div>
            </transition-group>
          </draggable>

          <div v-show="!mainBookmarkId" class="placeholder">
            <fa icon="arrow-up" />
            <br />
            Commencez par choisir un marque-page de départ.
          </div>

          <div
            v-show="mainBookmarkId && !conditions.length"
            class="placeholder"
          >
            Puis ajoutez des conditions à l'aide des boutons.
            <br />
            <fa icon="arrow-down" />
          </div>

          <div class="combinaison-footer">
            <el-row :gutter="5" type="flex">
              <el-col
                :span="8"
                v-for="key in Object.keys(operators)"
                :key="key"
              >
                <el-button
                  :type="operators[key].type"
                  @click="addCondition(key)"
                  :disabled="!isActionsAvailable"
                  plain
                >
                  <h3 class="inner-button-title-with-icon">
                    <div
                      v-if="operators[key].img"
                      :class="operators[key].class"
                    />
                    <fa
                      v-if="operators[key].icon"
                      :icon="['far', operators[key].icon]"
                      size="2x"
                    />
                    <div>
                      <h3>{{ operators[key].name }}</h3>
                      <div class="inner-button-description">
                        {{ operators[key].description }}
                      </div>
                    </div>
                  </h3>
                </el-button>
              </el-col>
            </el-row>
          </div>
        </el-col>

        <el-col :span="8">
          <el-card
            shadow="never"
            style="height: 100%"
            :body-style="{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }"
          >
            <div style="text-align: center; color: #606266">
              <fa :icon="['fal', 'users']" size="6x" />
              <h3>
                <el-tag
                  type="warning"
                  style="font-weight: bold; font-size: 1.1em"
                >
                  {{ total }}
                </el-tag>
                contacts uniques
              </h3>
              <div
                v-show="description"
                v-html="description"
                style="margin: 10px 0 0 0; color: #909399; font-size: 0.8em"
              />
              <br />
              <el-button
                type="primary"
                @click="validate"
                :disabled="!isFilterValid"
              >
                Créer le filtre
              </el-button>
              <br />
              <br />
              <el-switch
                v-model="createBookmark"
                active-text="Créer également un marque-page"
                :disabled="!isFilterValid"
              />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import qlikConfig from "@/config/qlik.js";
import enigma from "enigma.js";
import schema from "enigma.js/schemas/12.20.0.json";
import draggable from "vuedraggable";
import ColoredCard from "@/components/ColoredCard";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapGetters } = createNamespacedHelpers("qlik");
const { mapActions: mapBookmarksDrawerActions } = createNamespacedHelpers(
  "bookmarksDrawer"
);

const emailListQuery = (bookmarkQuery, spectatorField) => ({
  qInfo: { qId: "ListObject01", qType: "ListObject" },
  qListObjectDef: {
    qDef: {
      qFieldDefs: [
        `=aggr(ONLY({1<[${spectatorField}]=${bookmarkQuery}>}[${spectatorField}]),[${spectatorField}])`
      ],
      qFieldLabels: [`${spectatorField}`],
      qSortCriterias: [
        {
          qSortByAscii: 1,
          qSortByLoadOrder: 1,
          qSortByNumeric: 1,
          qSortByState: 1
        }
      ]
    },
    qInitialDataFetch: [{ qHeight: 10, qLeft: 0, qTop: 0, qWidth: 1 }],
    qLibraryId: "",
    qShowAlternatives: true,
    qStateName: "bookmarkManager"
  }
});

const getBookmarkTitle = (id, bookmarks) =>
  bookmarks.find(({ qInfo: { qId } }) => qId === id).qMeta.title;

const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost"
};

const operators = {
  "+": {
    name: "Ajouter",
    description:
      "Les contacts de ce marque-page seront ajoutés à ceux des précédents marque-pages.",
    type: "success",
    icon: "plus-circle"
  },
  "*": {
    name: "En commun avec",
    description:
      "Seuls les contacts qui figurent dans ce marque-page et dans les précédents seront retenus.",
    type: "primary",
    img: require("@/assets/intersection.png"),
    class: "intersection-icon"
  },
  "-": {
    name: "Retirer",
    description:
      "Les contacts de ce marque-page seront retirés à ceux des précédents marque-pages.",
    type: "warning",
    icon: "minus-circle"
  }
};

export default {
  components: { draggable, ColoredCard },
  created() {
    this.app.getList(
      "BookmarkList",
      ({ qBookmarkList: { qItems } }) =>
        // spread avoid sort side effect
        (this.bookmarks = [...qItems].sort(
          (
            // newly created bookmarks doesn't have a modifiedDate (Qlik bug?)
            { qMeta: { modifiedDate: firstDate = Date.now() } },
            { qMeta: { modifiedDate: secondDate } }
          ) => new Date(secondDate) - new Date(firstDate)
        ))
    );
  },
  async mounted() {
    const { host, prefix } = qlikConfig;
    const { qDocId } = this.app;
    this.enigmaSession = enigma.create({
      schema,
      url: `wss://${host}${prefix}app/${qDocId}`,
      createSocket: url => new WebSocket(url)
    });

    const global = await this.enigmaSession.open().catch(this.handleError);
    this.qlikDoc = await global.openDoc(qDocId).catch(this.handleError);
  },
  beforeDestroy() {
    this.enigmaSession.close();
  },
  data() {
    return {
      bookmarks: [],
      createBookmark: true,
      transitionGroupName: null,
      isDraggable: true,
      mainBookmarkId: "",
      conditions: [],
      total: 0,
      emailList: null,
      enigmaSession: null,
      qlikDoc: null,
      error: null
    };
  },
  computed: {
    ...mapState(["app"]),
    ...mapGetters(["hasTag"]),
    dragOptions: () => dragOptions,
    operators: () => operators,
    request() {
      return this.conditions
        .filter(({ operator, bookmarkId }) => operator && bookmarkId)
        .reduce(
          (acc, { operator, bookmarkId }) =>
            `(${acc} ${operator} P({'${bookmarkId}'}))`,
          `P({'${this.mainBookmarkId}'})`
        );
    },
    spectatorField() {
      return this.hasTag("ED360")
        ? {
            label: "identifiants des spectateurs",
            qlikField: "customer_provider_id"
          }
        : {
            label: "e-mails des spectateurs",
            qlikField: "Email du spectateur"
          };
    },
    isActionsAvailable() {
      return (
        this.mainBookmarkId &&
        this.availableBookmarks.length &&
        this.conditions.every(({ bookmarkId }) => bookmarkId)
      );
    },
    isFilterValid() {
      return (
        this.total &&
        this.conditions.length &&
        this.conditions.every(({ bookmarkId }) => bookmarkId)
      );
    },
    availableBookmarks() {
      return this.bookmarks.filter(
        ({ qInfo: { qId } }) =>
          qId !== this.mainBookmarkId &&
          !this.conditions.some(({ bookmarkId }) => qId === bookmarkId)
      );
    },
    description() {
      const { mainBookmarkId, bookmarks, conditions } = this;

      if (!mainBookmarkId || !conditions.length) {
        return "Aucune combinaison de marque-pages.";
      }
      const mainBookmarkTitle = getBookmarkTitle(mainBookmarkId, bookmarks);

      return conditions.reduce(
        (description, { operator, bookmarkId }, index) => {
          if (!bookmarkId) {
            return "Combinaison de marque-pages en cours de construction...";
          }

          let operatorLabel;
          switch (operator) {
            case "+":
              operatorLabel = "ajouté";
              break;
            case "*":
              operatorLabel = "fait l'intersection avec";
              break;
            case "-":
              operatorLabel = "retiré";
              break;
          }
          const bookmarkTitle = getBookmarkTitle(bookmarkId, bookmarks);

          return `${description}${
            index ? "puis " : ""
          }vous avez ${operatorLabel} les contacts du marque-page "${bookmarkTitle}"${
            index < conditions.length - 1 ? ", " : "."
          }`;
        },
        `A partir du marque-page "${mainBookmarkTitle}", `
      );
    }
  },
  methods: {
    ...mapBookmarksDrawerActions(["createCombined"]),
    getAvailableBookmarks(selectedBookmarkId) {
      const selectedBookmark = this.bookmarks.find(
        ({ qInfo: { qId } }) => qId === selectedBookmarkId
      );

      return selectedBookmark
        ? [selectedBookmark, ...this.availableBookmarks]
        : this.availableBookmarks;
    },
    addCondition(operator) {
      this.conditions.push({
        key: new Date().getTime(),
        operator,
        bookmarkId: ""
      });
    },
    async validate() {
      await this.app.selectionState().clearAll(true);
      await this.emailList
        .selectListObjectAll({
          qPath: "/qListObjectDef",
          qSoftLock: false
        })
        .catch(this.handleError);
      this.createBookmark && this.createCombined();
    },
    deleteCondition(key) {
      this.conditions.some(
        ({ key: conditionKey }, index) =>
          conditionKey === key && this.conditions.splice(index, 1)
      );
    },
    handleError(error) {
      this.error = "Une erreur inattendue est survenue.";
      throw new Error(error);
    }
  },
  watch: {
    async request() {
      this.emailList = await this.qlikDoc
        .createSessionObject(
          emailListQuery(this.request, this.spectatorField.qlikField)
        )
        .catch(this.handleError);
      const emailListLayout = await this.emailList
        .getLayout()
        .catch(this.handleError);
      this.total = emailListLayout.qListObject.qDimensionInfo.qCardinal;
    }
  }
};
</script>

<style scoped>
.card-header {
  font-weight: 600;
}

.alert {
  margin: 0 0 10px 0;
}

.inner-button-title-with-icon > div {
  margin-top: 3px;
  white-space: normal;
}

.inner-button-description {
  margin-top: 5px;
  font-size: 0.8em;
  opacity: 0.9;
}

.condition-operator {
  display: inline-block;
  position: absolute;
  left: 20px;
  top: -25px;
  z-index: 1;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  position: relative;
  margin: 15px 0;
}

.is-draggable {
  cursor: move;
}

.placeholder {
  color: #606266;
  text-align: center;
  margin-bottom: 20px;
}

.all-disabled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intersection-icon {
  height: 32px;
  background: center / contain no-repeat url(~@/assets/intersection.png);
}

.combinaison-footer button {
  width: 100%;
  height: 100%;
}

.combinaison-footer button[class*="el-button--primary"] {
  color: #409eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
}

.combinaison-footer button[class*="el-button--primary"]:hover,
.combinaison-footer button[class*="el-button--primary"]:focus {
  color: #ffffff;
  background: #409eff;
  border-color: #409eff;
}

.combinaison-footer
  button[class*="el-button--primary"]:hover
  .intersection-icon,
.combinaison-footer
  button[class*="el-button--primary"]:focus
  .intersection-icon {
  background: center / contain no-repeat url(~@/assets/intersection_hover.png);
}

.combinaison-footer
  button[class*="el-button--primary"]:disabled
  .intersection-icon {
  background: center / contain no-repeat url(~@/assets/intersection.png);
  opacity: 0.7;
}

.combinaison-footer button[class*="el-button--primary"]:active {
  color: #ffffff;
  background: #3a8ee6;
  border-color: #3a8ee6;
  outline: none;
}

.combinaison-footer button[class*="el-button--primary"]:disabled {
  color: #8cc5ff;
  background: #ecf5ff;
  border-color: #d9ecff;
}
</style>
